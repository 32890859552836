
import OportunidadService from "../../Services/OportunidadService";
import EmbudoService from "../../Services/EmbudoService";
import OportunidadAgregarModal from "./componentes/OportunidadAgregarModal.vue";
import FECHAS from "@/Constantes/Fecha";
export default {
  name: "oportunidad-gestor",
  components: { OportunidadAgregarModal },
  data() {
    return {
      breadcrumbs: [{ etiqueta: "Oportunidades" }],
      embudoOpciones: [],
      registroOpciones: FECHAS.FECHAS_FILTRO_REGISTRO,
      filtros: {
        busqueda: "",
        embudoIds: [],
        fechaInicio: "",
      },
      accionLimpiar: false,
      agenteOpciones: [],
      oportunidades: [],
      columnas: [
        {
          name: "folio",
          header: "#OP",
          align: "center",
          field: "folio_oportunidad",
          minWidth: 80,
          width: 80,
          templateName: "folio",
          routeName: "oportunidadesDetalle",
          rutaId: "oportunidad_id",
          type: "string",
        },
        {
          name: "nombreContacto",
          header: "Contacto",
          align: "left",
          field: "contacto_nombre_completo",
          minWidth: 140,
          width: 250,
        },
        {
          name: "articulo",
          header: "Artículo",
          align: "left",
          field: "articulo_nombre",
          minWidth: 140,
          width: 270,
        },
        {
          name: "descripcion",
          header: "Descripción",
          align: "left",
          field: "descripcion",
          minWidth: 140,
          width: 220,
          visible: false,
        },
        {
          name: "agenteAsignado",
          header: "Agente asignado",
          align: "left",
          field: "usuario_asignado",
          minWidth: 130,
          width: 150,
        },
        {
          name: "embudo",
          header: "Status",
          align: "left",
          field: "embudo_color",
          minWidth: 130,
          width: 150,
          templateName: "status",
          statusName: "embudo_titulo",
          filter: false,
        },
        {
          name: "valorOportunidad",
          header: "Valor oportunidad",
          align: "right",
          field: "valor_venta",
          minWidth: 140,
          width: 140,
          formatear: (val) => this.accounting.formatMoney(val),
        },
        {
          name: "registroFecha",
          header: "Registro",
          align: "left",
          field: "registro_fecha",
          format: "dd/MMM/yy HH:mm:ss",
          type: "datetime",
          minWidth: 140,
          width: 140,
          formatear: (val) => this.formatearFecha(val, 2, true),
        },
      ],
      // Variables para crear oportunidad
      modalAgregar: false,
      agregarOportunidadObj: {
        contactoId: "",
        contactoNombre: "",
        contactoTelefono: "",
        contactoEmail: "",
        articuloId: "",
        articuloNombre: "",
        articuloDescripcion: "",
        articuloValorEstimado: "",
        valorVenta: "",
        posibleFechaCierre: "",
        descripcion: "",
        agenteAsignadoId: Number(localStorage.getItem("autorId")),
        comentarios: "",
      },
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      let queryParams = this.$route.query;
      this.cargarFiltrosUrl(queryParams);
      await this.obtenerStatusEmbudo(false);
      await this.obtenerOportunidades(false);
      this.$nextTick(() => this.$refs.buscar.$el.focus());
      this.loader(false);
    },
    cargarFiltrosUrl(query) {
      this.filtros = {
        busqueda: query.b || "",
        embudoIds: this.obtenerArrayParametrosQuery(query.s, true), // El segundo parametro es para arrays con valores numericos
        fechaInicio: query.fi || "",
        ordenar: "folio_desc",
      };
    },
    replaceUrl() {
      let filtros = {
        b: this.filtros.busqueda,
        s: this.filtros.embudoIds,
        fi: this.filtros.fechaInicio,
      };
      this.$router.replace({ query: filtros }).catch(() => {});
    },
    async obtenerStatusEmbudo(loader = true) {
      if (loader) this.loader(true);
      await EmbudoService.listadoBasico({ ordenar: "orden_asc" })
        .then((resp) => {
          this.embudoOpciones = resp;
          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async obtenerOportunidades(loader = true) {
      if (loader) this.loader(true);
      this.replaceUrl();
      await OportunidadService.listarGestor(this.filtros)
        .then((resp) => {
          let oportunidades = resp;
          this.oportunidades = oportunidades.map((op) => {
            return {
              ...op,
              registro_fecha: new Date(op.registro_fecha),
            };
          });
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await OportunidadService.obtenerConcentradoOpciones({})
        .then((resp) => {
          this.agenteOpciones = resp.agentes;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async abrirModalAgregar() {
      if (_.isEmpty(this.agenteOpciones)) {
        await this.obtenerConcentradoOpciones();
      }
      this.modalAgregar = true;
    },
    cerrarModalAgregar() {
      this.modalAgregar = false;
    },
    async manejarExitoAlta(respuesta) {
      this.cerrarModalAgregar();
      await this.obtenerOportunidades();
      this.mostrarAlerta(respuesta.mensaje, "exito");
    },
    abrirModalFiltros() {
      console.log("filtros");
    },
    limpiar() {
      this.filtros = {
        busqueda: "",
        embudoIds: [],
        fechaInicio: "",
        ordenar: "folio_desc",
      };
      this.obtenerOportunidades();
      this.accionLimpiar = true;
      this.$refs.buscar.$el.focus();
    },
  },
};
